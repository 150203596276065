export const Jump = () => {
  return (
    <div className="jump-div">
      <p>Para pc: Pulsa la barra espaciadora para saltar 
        <br/>Para celular: Haz click en la pantalla para saltar</p>
<div style={{ width: "100%", height: "800px", border: "none" }}>
      <iframe
        src="https://lucianatux.github.io/bosque/"
        style={{ width: "100%", height: "100%", border: "none" }}
        title="Wordle"
      />
    </div>
    </div>
    
  );
};
